html {
  scroll-behavior: smooth;
}

.bg-full {
  background-image: url(./assets/bg_full.png);
  background-size: cover;
}

.bg_full {
  background-image: url(./assets/bg-full.png);
  background-size: cover;
}

.bg-feature {
  background-image: url(./assets/bg-feature.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.btn-gradient {
  background: linear-gradient(281.31deg, #F9FB32 -7.22%, #CD9400 23.04%, #F9FB32 131.36%);
}

.border-custom {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-radius: 8px;
  border-image-slice: 1;
  border-image-source: radial-gradient(48.6% 799.61% at 50% 50%, #F9FB32 0%, rgba(230, 233, 84, 0) 100%);
  filter: drop-shadow(4px 6px 61px rgba(99, 104, 231, 0.25));
}

.line {
  background: #F9FB32;
  animation: line 6s infinite linear;
}

@keyframes line {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}


@keyframes animateBg {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1);
  animation: animate 3s linear infinite;
  /* overflow: hidden; */
}

span::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg, #fff, transparent);
}

@keyframes animate {
  0% {
    transform: rotate(315deg) translateX(0);
    opacity: 1;
  }

  70% {
    opacity: 1;

  }

  100% {
    transform: rotate(315deg) translateX(-1500px);
    opacity: 0;

  }
}

span:nth-child(1) {
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 0;
  animation-duration: 1s;
}

span:nth-child(2) {
  top: 0;
  right: 80px;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 3s;
}

span:nth-child(3) {
  top: 80px;
  right: 0px;
  left: initial;
  animation-delay: 0.4s;
  animation-duration: 2s;
}

span:nth-child(4) {
  top: 0;
  right: 180px;
  left: initial;
  animation-delay: 0.6s;
  animation-duration: 1.5s;
}

span:nth-child(5) {
  top: 0;
  right: 400px;
  left: initial;
  animation-delay: 0.8s;
  animation-duration: 2.5s;
}

span:nth-child(6) {
  top: 0;
  right: 600px;
  left: initial;
  animation-delay: 1s;
  animation-duration: 3s;
}

span:nth-child(7) {
  top: 300px;
  right: 0px;
  left: initial;
  animation-delay: 1s;
  animation-duration: 1.75s;
}

span:nth-child(8) {
  top: 0px;
  right: 700px;
  left: initial;
  animation-delay: 1.4s;
  animation-duration: 1.25s;
}

span:nth-child(9) {
  top: 0px;
  right: 1000px;
  left: initial;
  animation-delay: 0.75s;
  animation-duration: 2.25s;
}

span:nth-child(10) {
  top: 0px;
  right: 1000px;
  left: initial;
  animation-delay: 2.75s;
  animation-duration: 2.25s;
}
